import React from 'react';
import {Link} from 'gatsby';
import useDarkMode from 'use-dark-mode';

import AnimationPanel from './animationPanel';
import ThemeToggle from './themeToggle';
import Social from "../components/social";

const Layout = (props) => {
  const {title, index, children} = props;
  let initDarkMode = false;
  if (typeof window !== `undefined`) {
    initDarkMode = JSON.parse(localStorage.getItem('darkMode'))
  }
    const darkMode = useDarkMode(initDarkMode);
    const style = {};
    if (index) {
        style["maxHeight"] = "calc(100vh - 214px)";
    }
  return (
    <div className={darkMode.value ? 'layout-dark' : 'layout'}>
      <header className={darkMode.value ? 'header-dark' : 'header'}>
        <AnimationPanel />
        <div className='header-nav'>
          <Social />
          <div className='header-toggle'>
            <ThemeToggle />
          </div>
        </div>
        <div className='header-text-container'>
          <Link to='/'>
            <h1>{title}</h1>
          </Link>
        </div>
      </header>
          <main style={style}>{children}</main>
      <footer>© {new Date().getFullYear()}, Katie Baker</footer>
    </div>
  );
};

export default Layout;
