import React from 'react';
import {Animated} from 'react-web-animation';

class AnimationPanel extends React.Component {
  render() {
    const circle = (
      <svg className='shape' id='circle' name='circle' viewBox='0 0 130 130'>
          <animateTransform
            attributeName='transform'
            begin='0s'
            dur='4s'
            type='rotate'
            from='0 5 5'
            to='360 5 5'
            repeatCount='indefinite'
          />
        <circle cx='65' cy='65' r='60' className='shape-inner'>
        </circle>
      </svg>
    );

    const square = (
      <svg className='shape' id='square' name='square' viewBox='0 0 130 130'>
        <animateTransform
          attributeName='transform'
          begin='0s'
          dur='4s'
          type='rotate'
          from='0 0 0'
          to='360 0 0'
          repeatCount='indefinite'
        />
        <rect x='5' y='5' width='120' height='120' className='shape-inner'>
        </rect>
      </svg>
    );

    const triangle = (
      <svg className='shape' id='triangle' name='triangle' viewBox='0 0 130 130'>
        <animateTransform
          attributeName='transform'
          begin='0s'
          dur='4s'
          type='rotate'
          from='0 0 0'
          to='360 0 0'
          repeatCount='indefinite'
        />
        <polygon points='65 10, 130 120, 0 120' className='shape-inner'>
        </polygon>
      </svg>
    );

    const pentagon = (
      <svg className='shape' id='pentagon' name='pentagon' viewBox='0 0 130 130'>
        <animateTransform
          attributeName='transform'
          begin='0s'
          dur='4s'
          type='rotate'
          from='0 0 0'
          to='360 0 0'
          repeatCount='indefinite'
        />
        <polygon
          className='shape-inner'
          style={{
            stroke: 'rgb(0, 0, 0)',
            fill: 'none',
            strokeWidth: 3,
          }}
          points="2 48.919 64.754 2 127 48.919 103.118 123.105 26.054 122.683"
        ></polygon>
      </svg>
    );

    function randomFromRange(min, max) {
      let random = Math.random() * max;
      if (random <= min) {
        // could be negative or positive
        random = random * [-1, 1][Math.floor(Math.random() * 2)];
      }
      return random;
    }

    // animate function
    function animate(elem, startX, startY, bounce, duration) {
      // set and store the start positions (they'll need to be the same for the end position too)
      let posStartX;
      if (startX === 'left') {
        posStartX = 0;
      } else {
        posStartX = 100;
      }

      // posX is the X position variable we'll update
      let posX = startX;

      // array for storing the keyframes
      let keyframes = [];

      // we're going to push a transform into the keyframes array for each bounce
      for (let i = 1; i <= bounce; i++) {
        let translateX;
        let translateY;

        // coordinate adjustments for different window widths
        let adjTranslateY = 130;
        if (typeof window !== `undefined`) {
          if (window.innerWidth < 400) {
            adjTranslateY = 75;
          } else if (window.innerWidth < 600) {
            adjTranslateY = 100;
          } else if (window.innerWidth < 900) {
            adjTranslateY = 120;
          }
        }

        // slightly different process for first and last bounce
        if (i === 1 || i === bounce) {
          // if we're on the right side we need to do an additional translate to account for the size of the shape div
          translateX = startX === 'right' ? `translateX(${posStartX}vw) translateX(-${adjTranslateY}px)` : `translateX(${posStartX}vw)`;
          translateY = `translateY(${startY}px)`;
        } else {
          // if we're on the right side we need to do an additional translate to account for the size of the shape div
          translateX = posX === 'right' ? `translateX(100vw) translateX(-${adjTranslateY}px)` : `translateX(0vw)`;
          // for randomly picking Y, we want to translate up or down so pick randomly from '-' or '' first, then pick the pixels randomly
          translateY = `translateY(${randomFromRange(adjTranslateY, 150)}px)`;
        }

        // store the transformation
        let transform = `${translateX} ${translateY}`;
        keyframes.push({transform: transform});

        // change the X position
        posX = posX === 'right' ? 'left' : 'right';
      }

      var options = {
        duration: duration,
        iterations: Infinity,
      };

      return (
        <Animated.div className='shape-container' keyframes={keyframes} timing={options}>
          {elem}
        </Animated.div>
      );
    }

    return (
      <div className='animation-container'>
        {animate(circle, 'left', 12, 5, 36000)}
        {animate(square, 'left', 60, 7, 37000)}
        {animate(triangle, 'right', 27, 5, 38000)}
        {animate(pentagon, 'right', 99, 7, 39000)}
      </div>
    );
  }
}

export default AnimationPanel;
