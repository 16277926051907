import React, {useState} from 'react';
import useDarkMode from 'use-dark-mode';
import Switch from '@material-ui/core/Switch';

const ThemeToggle = () => {
  // Instantiate with the default behavior, in this case, it defaults to light-mode
  // This places "light-mode" class on document.body, as outlined in my gatsby-config.js
  let initDarkMode = false;
  if (typeof window !== `undefined`) {
    initDarkMode = JSON.parse(localStorage.getItem('darkMode'))
  }
  const darkMode = useDarkMode(initDarkMode);

  // Custom function that handles the toggling
  // When called, it replaces the class on document.body and holds it in localStorage
  const handleTheme = (theme) => (theme ? darkMode.enable() : darkMode.disable());

  const [checked, setChecked] = useState(darkMode.value);

  return (
    <Switch
      checked={checked}
      onChange={() => {
        setChecked(!checked);
        handleTheme(!checked);
      }}
      size='small'
      style={{
        backgroundColor: darkMode.value ? '#EAE8FF' : '#271F30',
        color: darkMode.value ? '#271F30' : '#EAE8FF',
      }}
    />
  );
};

export default ThemeToggle;
