import React from 'react';
import {Link} from 'gatsby';

// import icons
import {FaTwitter} from 'react-icons/fa';
import {FaGithub} from 'react-icons/fa';
import {FaLinkedin} from 'react-icons/fa';
import {SiGooglescholar} from 'react-icons/si';
import {FaQuestion} from 'react-icons/fa';
import {ImBlog} from 'react-icons/im';

const Social = () => {

  return (
    <div className='social-container'>
      <a href="https://twitter.com/_katie_codes" target="_blank" rel="noreferrer" className='social-link'><FaTwitter size='1.5em' className='social-icon'/></a>
      <a href="https://github.com/katie-codes-io" target="_blank" rel="noreferrer" className='social-link'><FaGithub size='1.5em' className='social-icon'/></a>
      <a href="https://www.linkedin.com/in/katiecodes/" target="_blank" rel="noreferrer" className='social-link'><FaLinkedin size='1.5em' className='social-icon' /></a>
      <a href="https://scholar.google.co.uk/citations?user=qPLdCNYAAAAJ&hl=en" target="_blank" rel="noreferrer" className='social-link'><SiGooglescholar size='1.5em' className='social-icon' /></a>
      <Link size='1.5em' className='social-link' to='/blog'><ImBlog className='social-icon'/></Link>
      <Link size='1.5em' className='social-link' to='/about'><FaQuestion className='social-icon' /></Link>
    </div>
  );
};

export default Social;
